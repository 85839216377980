import SearchBox from '@/component/search-box/1.0.1/index.vue'
import Pagination from '@/component/pagination/index.vue'
import moment from 'moment'

import consumer_orderNo_center_flightChangeList from '@/lib/data-service/haolv-default/consumer_orderNo_center_flightChangeList'
import consumer_orderNo_center_chaStatusList from '@/lib/data-service/haolv-default/consumer_orderNo_center_chaStatusList'
import consumer_orderNo_center_cha_downloadDC from '@/lib/data-service/haolv-default/consumer_orderNo_center_cha_downloadDC'
import consumer_orderNo_center_userAllowBookingDepts from '@/lib/data-service/haolv-default/consumer_orderNo_center_userAllowBookingDepts'
export default {
    data() {
        return {
            loading: false,
            searchForm: {
                chaOrderNo: '',
                endDate: '',
                startDate: '',
                deptIds: [], //
                deptIdList: [],
                feeAffiliation: '',
                passengerName: '', // 改签乘客
                orderNo: '',   // 订单号
                changeStatus: 0,

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            },
            applyTime: [],
            deptList: [],
            totalCount: 0,   //总条数
            activeName: '0',
            tabList: [
                // 改签单状态 0.全部  31,改签中，33,待补款，34,已补款，35,改签成功，36.改签失败，37,已取消
                {
                    name: "0",
                    label: "全部",
                    value: 0
                },
                {
                    name: "31",
                    label: "改签中",
                    value: 0
                },
                {
                    name: "33",
                    label: "待补款",
                    value: 0
                },
                {
                    name: "34",
                    label: "已补款",
                    value: 0
                },
                {
                    name: "35",
                    label: "改签成功",
                    value: 0
                },
                {
                    name: "36",
                    label: "改签失败",
                    value: 0
                },
                {
                    name: "37",
                    label: "已取消",
                    value: 0
                },

            ],
            orderList: [],
            canDownload: true,
        }
    },
    components: {
        SearchBox,
        Pagination
    },
    created() {
    },
    mounted() {
    },
    activated() {
        this.getDeptList();
        this.getOrderList()
        this.getListNum()
    },
    deactivated() {
    },
    destroyed() {
    },
    watch: {
        applyTime: {
            handler (val) {
                if (val && val.length) {
                    this.searchForm.startDate = moment(val[0]).format('YYYY-MM-DD 00:00:00')
                    this.searchForm.endDate = moment(val[1]).format('YYYY-MM-DD 23:59:59')
                } else {
                    this.searchForm.startDate = ''
                    this.searchForm.endDate = ''
                }
            }
        },
    },
    computed: {},
    filters: {},
    methods: {
        changeDept() {
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
        },
        // 获取部门信息
        getDeptList() {
            consumer_orderNo_center_userAllowBookingDepts().then(res => {
                this.deptList = res.datas.deptList;
            })
        },
        onSearch() {
            this.searchForm.currentPage = 1
            this.getOrderList()
            this.getListNum()
        },
        onReset() {
            this.searchForm = {
                chaOrderNo: '',
                endDate: '',
                startDate: '',
                deptIds: [], //
                deptIdList: [],
                feeAffiliation: '',
                passengerName: '', // 改签乘客
                orderNo: '',   // 订单号
                changeStatus: 0,

                currentPage: 1,   //当前页码
                pageSize: 10,   //每页记录条数
            }
            this.applyTime = []
            this.onSearch()
        },
        getOrderList() {
            this.loading = true
            this.searchForm.changeStatus = parseInt(this.activeName)
            let deptId = [];
            this.searchForm.deptIdList.forEach(value => {
                value.forEach(value1 => {
                    deptId.push(value1);
                })
            })
            this.searchForm.deptIds = deptId;
            consumer_orderNo_center_flightChangeList(this.searchForm).then(res => {
                this.orderList = res.datas.list
                this.totalCount = res.datas.totalCount
            }).catch(err => {
                this.orderList = []
                this.totalCount = 0
            }).finally(() => {
                this.loading = false
            })
        },
        getListNum() {
            consumer_orderNo_center_chaStatusList(this.searchForm).then(res => {
                const flightOrderNum = res.datas.countVOList
                this.tabList.forEach(value => {
                    let isHaveNum = false
                    flightOrderNum.forEach(value1 => {
                        if (value1.changeStatus.toString() === value.name) {
                            isHaveNum = true
                            value.value = value1.orderNum
                        }
                    })
                    if (!isHaveNum) {
                        value.value = 0
                    }
                })
            })
        },
        downloadOrderExcel() {
            if (!this.canDownload) {
                return
            }
            this.canDownload = false
            consumer_orderNo_center_cha_downloadDC(this.searchForm).then(res => {
                let url = res.datas.orderDetailsUrl
                if (!url && url === '') {
                    this.$message({
                        type: "warning",
                        message: '下载失败，请稍候再试'
                    })
                    return
                }
                window.open(url, "_blank");
            }).finally(() => {
                this.canDownload = true
            })
        },
        handleDetailClick(val) {
            this.$router.push({
                name: 'admin-order-center-flight-change-detail',
                query: {
                    orderNo: val.chaOrderNo,
                    type: 'orderCenter'
                }
            })
        },
        getStatusToClass(val) {
            // 31,改签中，32,待补款，33,已补款，34,改签成功，36.改签失败，37,已取消
            if (val === 31 || val === 32 || val === 33) {
                return 'yellow-text'
            } else if (val === 34) {
                return 'green-text'
            } else {
                return 'red-text'
            }
        },
    }
}
